import React, { FC } from "react";
import { Link } from "gatsby";
import footerLogo from "../../images/footer-logo.svg";
import { email, phone } from "../../data/company-info";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import privacyPolicy from "../../files/PAIDT PRIVACY POLICY 2024.pdf";

const Footer: FC = () => {
  return (
    <footer className="bg-light">
      <div className="container py-lg lg:py-xl">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-lg lg:gap-0 items-start justify-between text-orange">
          <img
            className="h-10"
            src={footerLogo}
            alt="footer logo"
          />
          <div>
            <p className="font-bold font-oxanium mb-sm">General Inquires</p>
            <p className="font-medium font-manrope">{email}</p>
          </div>
          <div>
            <p className="font-bold font-oxanium mb-sm">Contact us</p>
            <p className="font-medium font-manrope">{phone}</p>
          </div>
          <div className="font-bold font-oxanium">
            <div className="flex items-center gap-sm mb-sm">
              <AnchorLink className="font-bold" to="/contact-us#faq">
                FAQ
              </AnchorLink>
              <a className="font-bold" href={privacyPolicy} target="_blank">
                Privacy Policy
              </a>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center gap-sm">
              <Link
                to="https://www.linkedin.com/company/paid-t/"
                target="_blank"
                className="underline font-medium"
              >
                Linkedin
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dark text-center text-white text-xs font-manrope py-xs">
        <p>
          Copyright © {new Date().getFullYear()}{" "}
          <Link to="https://invt.tech/" target="_blank">
            INVT
          </Link>{" "}
          - All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
