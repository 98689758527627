const navLinks:{name:string,route:string}[] = [
    {
        name: 'What We Do',
        route: '/'
    },
    {
        name: 'Who We Are',
        route: '/who-we-are/'
    },
    {
        name: 'Contact Us',
        route: '/contact-us/'
    },
];

export default navLinks;