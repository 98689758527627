import React, { FC, ReactNode } from "react";
import Footer from "./footer";
import Header from "./header";

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
