import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import headerLogo from "../../images/header-logo.svg";
import navLinks from "../../data/nav-links";
import favicon from "../../images/favicon.svg";
import useScrollYPos from "../../hooks/useScrollYPos";

const Header: FC = () => {
  const { pathname } = useLocation();
  const headerRef = useRef<HTMLElement>(null);
  const [shouldDisplayFixedNav, setShouldDisplayFixedNav] =
    useState<boolean>(false);
  const scrollYPos: number = useScrollYPos();

  useEffect(() => {
    if (
      headerRef.current?.offsetHeight &&
      scrollYPos > headerRef.current?.offsetHeight
    ) {
      setShouldDisplayFixedNav(true);
    } else {
      setShouldDisplayFixedNav(false);
    }
  }, [scrollYPos]);
  return (
    <>
      <header
        ref={headerRef}
        className="py-lg lg:py-xl relative overflow-hidden"
      >
        <div className="header-gradient absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full origin-center"></div>
        <div className="container relative">
          <Link to="/">
            <img
              className="h-10"
              src={headerLogo}
              alt="header logo"
            />
          </Link>
          <h1 className="font-medium font-oxanium text-c-xl text-white py-lg lg:py-xl md:max-w-3xl">
            <span>Data is the foundation of any business,</span>{" "}
            <span>therefore trading as well.</span>{" "}
            <span> When you get that right that's how you move forward.</span>{" "}
          </h1>
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-y-lg lg:gap-y-20 lg:justify-between font-manrope lg:w-full">
            <p className="text-white max-w-xl font-manrope">
              Price Action Intelligent Detection (PAID) is a scalable
              software solution for investment funds, banks, proprietary trading
              companies, brokers, and digital banks.
            </p>
            <nav className="flex justify-center gap-sm border-lg rounded-radius border-white py-sm px-sm font-semibold text-sm md:text-base w-full md:w-auto">
              {navLinks.map((link, index) => (
                <div className="text-center" key={index}>
                  <Link
                    className={`${
                      pathname === link.route ? "text-dark" : "text-white"
                    }`}
                    key={index}
                    to={link.route}
                  >
                    {link.name}
                  </Link>
                  <div
                    className={`h-1 w-full rounded-radius bg-dark transition-all duration-duration ${
                      pathname === link.route ? "opacity-100" : "opacity-0"
                    }`}
                  ></div>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </header>
      <div
        className={`bg-dark fixed left-1/2 -translate-x-1/2 bottom-5 flex items-center justify-center gap-x-sm p-sm rounded-radius z-50 w-[calc(100%-32px)] md:w-auto ${
          shouldDisplayFixedNav ? "block" : "hidden"
        }`}
      >
        <img className="hidden md:block w-5" src={favicon} alt="icon" />
        {navLinks.map((link, index) => (
          <div className="text-center" key={index}>
            <Link
              className={`font-manrope font-semibold text-sm md:text-base ${
                pathname === link.route ? "text-orange" : "text-white"
              }`}
              key={index}
              to={link.route}
            >
              {link.name}
            </Link>
            {pathname === link.route && (
              <div className="h-1 w-full rounded-radius bg-orange"></div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Header;
