export const email:string = 'info@paid-t.com';
export const phone:string = '(+382) 63 229 668';


export const companyInfo:{type:string,content:string}[] = [
    {
        type: "Phone",
        content: phone
    },
    {
        type: 'Address',
        content: 'Balšića 44, 81 Podgorica, Montenegro'
    },
    {
        type: 'Email',
        content: email
    }
];