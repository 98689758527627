import { useEffect, useState } from 'react'

const useScrollYPos = () => {
  const [pos,setPos] = useState<number>(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
        setPos(window.scrollY);
    })
  },[]);

  return pos;
}

export default useScrollYPos;